.no-permissions-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.no-permissions {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 1000;
    position: absolute;
}

.main-container {
    display: flex;
    margin-top: -66px;
    width: 100%;
    height: 100%;
}