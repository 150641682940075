.header-container {
    color: #172B4D;
    background-color: #FFFFFF;
    margin-bottom: 5px;
    height: 60px;
    width: 100%;
    box-shadow: 0px 3px 5px rgb(9 30 66 / 20%), 0px 0px 1px rgb(9 30 66 / 31%);
    z-index: 100;
}

.header-logotype {
    position: absolute;
    left: 72px;
    top: 19px;
}

.header-user-info-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding-right: 2px;
}

#info-block {
    padding-top: 17px;
    z-index: 1000;
}

.user-avatar {
    padding-top: 5px;
    padding-right: 11px;
    z-index: 100;
}

.logout-button_slide {
    background: transparent;
    width: 24px;
    height: 24px;
    border: transparent;
    padding-top: 16px;
    margin-right: 12px;
    z-index: 1000;
}
@media screen and ( max-width: 480px){
    .logout-button_slide{
        z-index: 100;
    }
}
@media screen and ( max-width: 1250px){
    .header-logotype{
        left: 20px;
    }
}