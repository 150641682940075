.querySelect.accordion {
    padding-top: 65px
}

.CircularProgressbar .CircularProgressbar-path {
    stroke: #42526E !important;
}

.css-ozkbo8 {
    stroke-width: 1.0 !important;
}

.CircularProgressbar {
    width: 150px !important;
    height: 150px !important;
}

.query-table-button {
    min-height: 30px !important;
    height: 30px !important;
    justify-content: space-around;
    width: 126px;
    display: flex;
}

.pivot-dots-container {
    line-height: 6px !important;
    min-height: 40px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
}

.pivot-table-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding-right: 6px;
    padding-bottom: 54px;
}

.pivot-table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}


.delete-button {
    background: transparent;
    border: none;
}

.row-container {
    display: flex;
    flex-direction: row;
    width: 100px;
    margin-left: 40px;

}

.input-search-container {
    margin-top: 80px;
}

.add-waypoint {
    margin-left: 20px;
}

.input-tag {
    border-radius: 2px;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    margin-top: 16px;
    padding-top: 11px;
    width: 150px;
    flex-direction: row;
}

.input-tag input {
    border: none;
    width: 145px;
}

.input-tag__tags {
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.wrap_container {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.input-tag__tags li {
    align-items: center;
    background: rgb(0, 70, 175);;
    border-radius: 2px;
    color: white;
    font-size: 16px;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
}

.input-tag__tags li button {
    align-items: center;
    appearance: none;
    background: #333333;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-size: 18px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding-left: 6px;

    transform: rotate(45deg);
    width: 15px;
}

.input-tag__tags li button:active {
    background: red;
}

.input-tag__tags li.input-tag__tags__input {
    background: none;
    flex-grow: 1;
    padding: 0;
}

.button-submit {
    margin-left: 5px;
}

#link {
    margin-left: 5px;
    font-size: 144%;
    height: 20px;
    margin-right: 4px;
    width: 20px;
}

.waypoints {
    width: 100%;
    height: 36px;
}

.input-container {
    display: flex;
    margin-top: 6px;
    margin-bottom: 5px;
    height: 35px;
}


#myGrid {
    width: 100%;
    height: 100%;
}


.green-point {
    color: green;
    font-weight: bold;
}

.yellow-point {
    color: yellow;
    font-weight: bold;
}

.red-point {
    color: red;
    font-weight: bold;
}

@media screen and (max-width: 660px) {
    .input-container {
        flex-wrap: wrap;
        height: auto;
        justify-content: flex-end;
    }
}