iframe {
  display: none;
}

body {
  background: #FFFFFF;
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root *::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 16px;
}

#root *::-webkit-scrollbar-track {
  background-color: #fff;
}

#root  *::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 16px;
  border: 4px solid #fff;
}

#root *::-webkit-scrollbar-button {
  display: none;
}
