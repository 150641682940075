.container {
    display: flex;
    background: black;
    justify-content: center;
    align-items: center;
}

.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 1000;
    position: absolute;
}


.logotype {
    position: absolute;
    z-index: 10000;
    margin-top: 15px;
    margin-left: 15px;
}

body, #root, html, .container , .logo-container {
    height: 100%;
    width: 100%;
}


.login-container > * {
    letter-spacing: 0.2px;
    line-height: 30px;
    font-weight: 600;
    font-size: 20px;
}

.login-form {
    margin-top: -100px;
    padding: 50px;
    border-radius: 8px;
    background: #F1F1F1;
}


.login-button_slide {
    color: #FFFFFF;
    border-radius: 5px;
    border: none;
    height: 50px;
    width: 150px;
    display: inline-block;
    background-color: #56698F;
    font-size: 20px;
    cursor: pointer;
    box-shadow: inset 0 0 0 0 #FF5630;
    -webkit-transition: ease-out 0.4s;
    -moz-transition: ease-out 0.4s;
    transition: ease-out 0.4s;
}


.login-slide_right:hover {
    box-shadow: inset 400px 0 0 0 #FF5630;;
}

#greeting {
    display: flex;
    width: 271px;
    border-radius: 15px;
    color: #FFFFFF;
    background-color: #56698F;
    justify-content: space-around;
    flex-wrap: nowrap;
    flex-direction: row;
}




