.App {
    text-align: center;
}

.spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.highcharts-credits{
    display: none !important;
}

.center-spinner-block{
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
    width: 100%;
    z-index: auto;
    position: absolute;
}

.text {
    color: white;
    fill: white;
}

body{
    font-family: "Roboto" , sans-serif;
}
.atlaskit-portal-container{
    background: transparent;
}