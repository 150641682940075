.bm-item {
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #42526E;
}

.bm-item:hover {
    border-radius: 3px;
    background: #DEEBFF;
    width: 210px;
}

.bm-burger-button {
    position: fixed;
    justify-content: center;
    width: 12px;
    height: 10px;
    left: 12px;
    z-index: 100;
    top: 25px;
}

.bm-burger-bars {
    background: #172B4D;
}

.bm-menu-wrap {
    z-index: 100 !important;
}

.bm-menu {
    background: #FAFBFC;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 8px 8px;
    margin-top: 60px;
    width: 240px;
    z-index: 100;
    font-size: 1.15em;
    cursor: pointer;
    box-shadow: 0px 3px 5px rgb(9 30 66 / 20%), 0px 0px 1px rgb(9 30 66 / 31%);
}

.bm-overlay {
    background: transparent !important;
}

.bm-item-list {
    color: #b8b7ad;
}

.big-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    border-radius: 3px;
}

#menu-text-item {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #42526E;
    padding-left: 5px;
}

.sidenav---icon-bar---u1f02 {
    background-color: black !important;
    color: black;
    z-index: 100 !important;
}

.sidebar {
    background: rgba(0, 0, 0, 0) !important;
    box-shadow: 0px 62px 5px rgb(9 30 66 / 20%), 0px 62px 1px rgb(9 30 66 / 31%);
    z-index: 200 !important;
    min-width: 60px !important;
    left: -3px !important;
    /*position: unset !important;*/
}

.sidenav---sidenav-toggle---1KRjR {
    z-index: 100 !important;
}

.dashboard {
    height: 100%;
    margin-top: -3px;
}

.dashboard > div > div > div:first-child {
    margin-right: -3px !important;
}

.toggle {
    width: 33px;
}
@media screen and (max-width: 1250px){
    .sidenav---sidenav-toggle---1KRjR {
        pointer-events: none;
    }
    .toggle {
       display: none;
    }
}