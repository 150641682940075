@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');

.ql-font-roboto {
  font-family: 'Roboto', sans-serif;
}

.ql-editor p {
    font-size: 16px;
}

.ql-font-segoeUI {
  font-family: 'Segoe UI', sans-serif;
}

.notifications-main-container {
    width: auto;
    height: 100%;
    padding-bottom: 51px;
}

.notification-inner-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 494px;
    justify-content: space-between;
    flex-wrap: nowrap;
}

.css-1ym3ssn-Icon {
    display: none !important;
}

.notification-button {
    display: flex;
    min-height: 30px !important;
    height: 30px !important;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    width: 208px;
}

.notification-button-last-view {
    display: flex;
    min-height: 30px !important;
    height: 34px !important;

}

.quill {
    height: 100%;
}
.ql-container {
    height: 100% !important;
}


.notification-selectors-container {
    display: flex;
    width: 700px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.multi-select-container {
    padding: 4px;
    width: 224px;
    z-index: 1000;
}

.multi-select-container-user {
    padding: 4px;
    width: 224px;
    z-index: 1000;
}

.notifications-main-container>.select-container {
    width: 224px;
    padding: 4px;
    z-index: 1001;
}

.select-category-container {
    width: 224px;
    z-index: 1;
}

.css-1a7rm5r-control {
    max-height: 40px;
    overflow: auto;
}

.css-xji0fq-IndicatorsContainer {
    margin-bottom: auto;
}

.notification-select {
    display: flex;
    width: 100%;
    height: 40px;
    flex-direction: row;
    justify-content: flex-start;
}

.title-container {
    padding-top: 11px;
    padding-left: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}


#title {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    flex-direction: column;
    width: 100%;
}

#title-text {
    width: 100%;
    height: 40px;
    margin-bottom: 16px;
}

.text-editor {
    padding-left: 5px;
    width: 100%;
}

.notification.accordion {
    padding-top: 65px
}


.notification-modal-container {
    padding-bottom: 8px;
    /*padding-top: 60px*/
}

.css-1bx7l6n-control {
    max-height: 40px !important;
    overflow: scroll !important;
}

.checkbox-select {
    color: black;
}

.selector-container {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.html-previewer-container {
    height: 100%;
    width: 440px;
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31) !important;
}


.html-previewer-inter {
    width: 100%;
    height: 100%;
}

.required {
    color: red;
}

.html-viewer {
    max-height: 100%;
    font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
    text-decoration: none solid rgb(51, 51, 51);
    font-weight: 400;
    line-height: 20px;
    font-size: 16px;
    color: #172b4d;
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 5px;
}

.html-viewer p {
    margin: 0;
}

.table-preview-button {
    margin-top: 3px;
    margin-bottom: 3px;
}

.text-wrapper-container {
    line-height: 0.8;
}

.html-renderer-container {
    width: 408px;
    margin-top: 5px;
    margin-left: 2px;
}


.block-type-btn.css-1gcladz-ButtonBase {
    z-index: 100;
}

.date-picker {
    width: 230px;
}

#toggle-to-al-label {
    display: flex;
    padding-top: 31px;
    padding-left: 13px;
}

#toggle-to-al-label>p {
    padding-top: 4px;
}

.css-1pmoact {
    --modal-dialog-width: 440px;
    --modal-dialog-height: auto;
    box-shadow: 0px 3px 5px rgb(9 30 66 / 20%), 0px 0px 1px rgb(9 30 66 / 31%);
    border-radius: 5px;
}

.notification-title-preview {
    text-align: left;
    color: #172b4d;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: -.005em;
}

.notification-Last-View {
    text-align: left;
    color: #172b4d;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 16px;
    font-weight: 800;
    font-style: normal;
    letter-spacing: -.005em;
}

.css-dk2yag-ModalHeader {
    padding: 27px !important;
    padding-bottom: 3px !important;
}

.css-95a6y4-ModalBody {
    padding: 2px 15px !important;
}

.notifications-main-container * .ag-theme-alpine .ag-cell {
    display: flex !important;
    flex-direction: column !important;
    align-content: center !important;
    justify-content: flex-start !important;
    border: 1px solid transparent;
    padding-left: 17px !important;
    padding-right: 17px !important;
    line-height: 16px !important;
    -webkit-font-smoothing: subpixel-antialiased !important;
    /*margin-top: 2px !important;*/
}

.notifications-main-container * .ag-theme-alpine .ag-row {
    font-size: 15px !important;
}

.notifications-main-container * .ag-cell-wrapper {
    margin-top: 2px;
}

.select-container-main {
    z-index: 100;
    padding: 5px;
    width: 170px;
}

.notification-button-main {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

.available-notifications {
    margin-top: -5px;
}

.notification-button-Create-notification {
    height: 39px !important;
}

@media screen and (max-width: 1310px) {
    .notification-inner-container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .html-previewer-container {
        width: 440px;
        height: 100%;
    }

    .title-container {
        flex-wrap: wrap;
    }

    .notification-select {
        flex-wrap: wrap;
        height: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 850px) {
    .notification-select {
        flex-wrap: wrap;
        height: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-bottom: 80px;
    }
}

@media screen and (max-width: 630px) {
    .notification-select {
        flex-wrap: wrap;
        height: 0;
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-bottom: 130px;
    }

    .multi-select-container-user {
        padding: 4px;
        width: 224px;
        z-index: auto;
        margin-top: -8px;
    }

    .multi-select-container {
        z-index: auto;
    }
}

@media screen and (max-width: 540px) {
    .select-container {
        z-index: auto;
    }
}

@media screen and (max-width: 470px) {
    .available-notifications {
        margin-top: -5px;

    }
}
