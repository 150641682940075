.inner-dashboard {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 17px);
    overflow: auto;
    padding: 5px 0 50px 0;
}

.jsoneditor-contextmenu .jsoneditor-menu button.jsoneditor-expand {
    border: transparent !important;
}

.history-container * .ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
    padding-left: 55px !important;
    padding-right: 18px !important;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 760px;
    min-width: 280px;
    padding: 5px 0 50px 0;
}

.css-4mp3pp-menu {
    width: 216px !important;
    z-index: 1000;
}

.accordion-item {
    border: none !important;
    height: 100% !important;
}

/*стилі перекрити треба бустрапа*/

.dashboard.accordion {
    margin-bottom: 35px;
}

.dashboard-one.accordion {
    margin-bottom: 35px;
}

.accordion-body {
    background: #FFFFFF !important;
    height: 100% !important;
    width: 100% !important;
    border: transparent !important;
    padding: 0 !important;
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31) !important;
}

.accordion-body > div > div{
    overflow: auto !important;
}

.hubspot-value-container > p{
    white-space: nowrap;
}

.accordion-button:not(.collapsed) {
    background: #FFFFFF !important;
    color: black !important;
    border-bottom: none !important;
    box-shadow: 0px 0px 0px rgb(9 30 66 / 20%), 0px -0.5px 3px rgb(9 30 66 / 31%) !important;
    padding: 14px 10px !important;
}

.accordion-button {
    background: #FFFFFF !important;
    border: none !important;
    box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2), 0px 0px 1px rgba(9, 30, 66, 0.31) !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 21px !important;
    color: #212529 !important;
    padding: 14px 10px !important
}

.accordion-button::after {
    margin-right: 8px !important;
}

/*стилі перекрити треба бустрапа*/

.dashboard-container>* {
    width: 100%;
}

.dropdown-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 30px;
}

.select-container {
    width: 224px;
    height: 40px;
    padding: 4px;
    z-index: 100;
}


.installed-addons-button {
    padding: 0px 6px;
    width: 34px;
    height: 16px;
    top: 2px;
    background: #0052CC;
    border-radius: 8px;
    align-self: center;
    margin: 0px 4px;
    color: white;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

/*JSON Editor теж треба перекрити*/

.jsoneditor input {
    margin-top: 5px
}

.css-hkzqy0-singleValue {
    line-height: 18px !important;
}


.jsoneditor {
    border: none !important;
    background: #F4F5F7 !important;
    overflow: auto;
    max-height: 260px;
}

div.jsoneditor-outer.has-nav-bar {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

div.jsoneditor-value.jsoneditor-string {
    color: black !important;
}

.jsoneditor-navigation-bar {
    display: table-column !important;
}

div.jsoneditor-menu {
    border-color: #4b4b4b;
}

div.jsoneditor-tree button.jsoneditor-collapsed:after {
    content: "\25B8" !important;
    font-size: 20px;
}

div.jsoneditor-tree button.jsoneditor-expanded:after {
    content: "\25BE" !important;
    font-size: 20px;
}

div.jsoneditor-tree button.jsoneditor-contextmenu-button:after {
    /*content: "\2630" !important;*/
    content: "\2699" !important;
}

div.jsoneditor-tree button.jsoneditor-dragarea:after {
    content: "\21C5" !important;
    display: none !important;

}


table.jsoneditor-search div.jsoneditor-frame {
    background: #808080;
}

div.jsoneditor textarea.jsoneditor-text {
    /*background-color: #666666;*/
    /*color: #ffffff;*/
    color: #e30000;
}

tr.jsoneditor-highlight,
tr.jsoneditor-selected {
    border-color: #808080;
}

div.jsoneditor-field[contenteditable=true]:focus,
div.jsoneditor-field[contenteditable=true]:hover,
div.jsoneditor-value[contenteditable=true]:focus,
div.jsoneditor-value[contenteditable=true]:hover,
div.jsoneditor-field.jsoneditor-highlight,
div.jsoneditor-value.jsoneditor-highlight {
    background-color: transparent;
    border-color: #b1b1b1;
}

div.jsoneditor-field.highlight-active,
div.jsoneditor-field.highlight-active:focus,
div.jsoneditor-field.highlight-active:hover,
div.jsoneditor-value.highlight-active,
div.jsoneditor-value.highlight-active:focus,
div.jsoneditor-value.highlight-active:hover {
    background-color: #b1b1b1;
    border-color: #b1b1b1;
}

div.jsoneditor-tree button:focus {
    background-color: #868686;
}

div.jsoneditor-readonly {
    color: #acacac;
}

div.jsoneditor td.jsoneditor-separator {
    color: #acacac;
}

div.jsoneditor-value.jsoneditor-string {
    color: #00ff88;
}

div.jsoneditor-value.jsoneditor-object,
div.jsoneditor-value.jsoneditor-array {
    color: #006fff;
}

div.jsoneditor-value.jsoneditor-number {
    color: #0041ff;
}

div.jsoneditor-value.jsoneditor-boolean {
    color: #ff8048;
}

div.jsoneditor-value.jsoneditor-null {
    color: #49a7fc;
}

div.jsoneditor-value.jsoneditor-invalid {
    color: #ff0000;
}

/*css-7bs0bd-ButtonBase*/
.save-json-button {
    margin-bottom: 6px;
    margin-left: 6px;
    width: 97px;
    margin-top: 6px;
}

/*JSON Editor теж треба перекрити*/

/*сорі атласіановські стилі перекрити треба*/
#editor-0-tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: stretch;
    flex-direction: row;
    align-items: flex-end;
}

#editor-1-tab {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: stretch;
    flex-direction: row;
    align-items: flex-end;
}


.save-json-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/*сорі атласіановські стилі перекрити треба*/

.form-text {
    color: white;
}

.activity-container {
    display: flex;
    line-height: 0.8;
}

.activity-text-container {
    display: table;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    height: 88px;
    margin: 0px 8px
}

.activity-time-container {
    display: table;
    top: 0px;
    flex-grow: 0;
    margin: 0px 8px;
}


.user-profile-container {
    display: flex;
    padding: 0px;
    max-width: 760px;
    left: 0px;
    top: 29px;
    flex-grow: 0;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.user-info-container {
    padding-left: 5px;
    padding-top: 3px;
    max-width: inherit;
    display: flex;
    background: #FFFFFF;
    border-radius: 5px;
    flex-direction: column;
}

.application-roles-container {
    padding-left: 3px;
    width: 180px;
    height: 100%;
    margin-left: 10px;
    background: #3D3E3F;
    /*background: #F4F9FE;*/
    border-radius: 10px;
}

.user-groups-container {
    padding-left: 3px;
    width: 180px;
    height: 300px;
    margin-left: 10px;
    background: #3D3E3F;
    /*background: #F4F9FE;*/
    border-radius: 10px;
}

.span-container {
    display: flex;
    flex-direction: row;
}

.card {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
}

.mb-2 {
    border-radius: 10px;
}

.flex-container {
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.wrap {
    text-align: left;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex-item {
    text-align: left;
    flex-wrap: wrap;
    margin: 5px;
}

.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 5px;
}

.user-avatar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 187px;
    height: 64px;
    margin: 8px 0px;
}

#name {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    width: 107px;
    color: #172B4D;
    margin: 0px 16px;
}

#avatar {
    width: 64px;
    height: 64px;
    border-radius: 64px;
    margin: 0px 16px;
}

.roles-container {
    display: flex;
    flex-direction: row;
    padding: 16px;
    max-width: 318px;
    left: 318px;
    top: 0;
    background: #FFFFFF;
    border-radius: 5px;
    align-self: stretch;
    flex-grow: 1;
    transition: margin-left 0.5s;
    min-width: 200px;
    flex-wrap: wrap;
    align-content: flex-start;
}

#roles-text {
    height: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #172B4D;
}

.badge-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 8px 0px;
    max-height: 50px;
    overflow: auto;
    max-width: 300px;
    min-width: 280px;
}

#badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 8px;
    /* width: 83px; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 338px;
    height: 22px;
    color: #FFFF;
    background: #0052CC;
    border-radius: 8px;
    flex-grow: 0;
    margin: 0 8px 3px 0;
}


.hubspot-container {
    display: flex;
    /* flex-direction: row; */
    /* align-items: flex-start; */
    max-width: 760px;
    /* height: 100%; */
    /* align-self: stretch; */
    /* margin: 8px 0px; */
    flex-wrap: wrap;
}

.hubspot-left-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 12px;
    width: 100%;
    line-height: 0.7;
    height: 100%;

}

.hubspot-right-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 12px;
    max-width: 100%;
    line-height: 0.7;
    transition: margin-left 0.5s;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin: 0px 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    /* line-height: 16px; */
    color: #172B4D;

}

.text-message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin: 0px 8px;
}

.empty-state {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    padding: 10px;
}

.css-ofy9t6-TabsWithMode [role="tabpanel"] {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    max-width: 760px;
    min-width: 280px;
    justify-content: space-between;
}

.selected-by-text {
    padding-top: 13px;
}

.jsoneedit {
    width: 100%;
}

.data-highcharts-chart {
    overflow: scroll !important;
}

.css-1ks9aww-gutter{
    background: #e0ebf3 !important;
}

@media screen and (max-device-width: 850px) {
    .selected-by-text {
        display: none;
    }
}

@media screen and (max-device-width: 835px) {
    .roles-container {
        margin-left: 45px;
    }
}

@media screen and (max-device-width: 800px) {
    .selected-by-text {
        display: none;
    }

    .roles-container {
        margin: auto;
    }
}

.user-selector {
    padding-bottom: 20px;
}

.addons-button {
    padding-bottom: 10px;
}

@media screen and (max-device-width: 775px) {
    .we-also-text {
        display: none;
    }

    .product-selector {
        padding: 7px;
    }

    .addons-button {
        padding: 7px;
    }

    .account-selector {
        padding: 7px;
    }

    .user-selector {
        padding: 7px;
    }

    .dropdown-container {
        flex-direction: column;
    }
}

@media screen and (max-device-width: 755px) {
    .roles-container {
        margin: 0;
    }
}

@media screen and (max-device-width: 725px) {
    .user-profile-container {
        justify-content: center;
    }
}

@media screen and (max-device-width: 700px) {
    .css-ofy9t6-TabsWithMode [role="tabpanel"] {
        justify-content: flex-start;
    }

    .hubspot-left-container {
        padding-top: 5px;
    }

    .hubspot-right-container {
        padding-top: 0;
    }
}

@media screen and (max-device-width: 470px) {
    #info-block {
        display: none;
    }
}

@media screen and (max-width: 412px) {

    .activity-container {
        max-width: 240px;
    }

    .roles-container {
        max-width: 240px;
    }

    .badge-container {
        max-width: 240px;
        min-width: 100px;
    }

    .activity-text-container {
        line-height: 19px;
    }

    .activity-time-container {
        line-height: 1;
    }

    .text-container {
        line-height: 14px;
    }

    .text-message-container {
        line-height: 16px;
    }
}


@media screen and (max-width: 380px) {
    .text-message-container {
        margin: 0;
    }

    .text-container {
        margin: 0;
    }
}

.linked-issues-toggler {
    background: transparent;
    width: 10px;
    height: 10px;
    border: transparent;
    z-index: 1000;
}

.linked-issue-card {
    max-height: 35px;
}

.linked-issue-card-body {
    padding: 0.3rem 1rem !important;
}