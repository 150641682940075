.hide-text {
    max-width: 100px;
    height: 30px;
    font-size: 10px;
    -webkit-text-stroke-width: 2px;
    -webkit-text-security: disc;
}

.td-pass{
    max-width: 100px;
    text-overflow: clip;
    white-space: nowrap;
    overflow: hidden;
}
.list-group-item {
    display: flex;
    justify-content: space-between;
}

.create-user-container {
    display: flex;
    flex-direction: column;
    color: black;
}

.create-user-label {
    padding-bottom: 5px;
    width: 300px;
}